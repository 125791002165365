import { useState } from "react";
//import { ethers } from "ethers";
import { setUp, runCompletion, callLambda } from "./chat.js";

function App() {
  let [contractText, setText] = useState("");
  let [text2, setText2] = useState("");
  //let [connected, setConnected] = useState(false);
  const [qType, setqType] = useState('');
  const handleChange = (e) => {
    setqType(e.target.value);
  }

  // let { ethereum } = window;
  // let contract = null;

  // if (ethereum) {

  //   let abi = [
  //     "function changeText(string)",
  //     "function text() view returns (string)"
  //   ]
  //   let address = "0x1C3dd5c848102ac51E1c47434a00eFbEd1F177C4";
  //   let provider = new ethers.providers.Web3Provider(ethereum);
  //   let signer = provider.getSigner();
  //   contract = new ethers.Contract(address, abi, signer);
  // }

  return (
    <div className="App">
      <div className="Header">
      <img src="./eth-glyph-colored.png" alt="Logo" width={100}/>
      <h1>Solidity Checker</h1>
      
      <p className="Headline">Made by Extropy with ChatGPT4</p>
      


      </div>
      
      {/* <button onClick={() => {
          if (contract && !connected) {
              ethereum.request({ method: 'eth_requestAccounts'})
                  .then(accounts => {
                      setConnected(true);
                  })
          }
      }}>{!connected ? 'Connect wallet' : 'Connected' }</button> */}




      <form class="form-group" onSubmit={(e) => {
        e.preventDefault();


          let start = contractText.selectiosnStart;
          let end = contractText.selectionEnd;
          let details = contractText.substring(start, end);

          setText2("checking .....");
 
          callLambda(details,qType)
          .then(result => {
            setText2(result);
            console.log(result);
            console.log("done");
          }
            
            )  
        
          // setUp()
          //   .then(openAISetup => {

          //     runCompletion(openAISetup, details, qType)
          //       .then(results => {
          //         setText2(results);
          //       })
          //   })
         

      }}>


        <h2 className="InputH2">Input your solidity contract code in the box:</h2>
        <br />
        <textarea rows={12} cols={80} onChange={e => setText(e.currentTarget.value)} value={contractText} />
        <br />
        <fieldset className="buttonsBox">
          <legend>Choose type:</legend>

        
  
          <input type="radio" value="security" id="sec"
            onChange={handleChange} name="questionType" defaultChecked />
            <label for="sec"> Security</label>
          <br />
          <input type="radio" value="optimise" id="opt"
            onChange={handleChange} name="questionType" />
            <label for="opt"> Optimisation</label>
        <br />
       
        </fieldset>
        <input className="button" type="submit" value="Ask ChatGPT4" />
      </form>
      <h2 className="InputH2Results">Results will appear here:</h2>
      <textarea rows={18} cols={80} value={text2} />
      <br />

      <div className="footer">
            <p><b>Disclaimer:</b> This tool is released as is for research purposes only. The results given are not security advice and should not be taken as such.</p>
              <br /> 
              <div className="footerBox">

             
      <p className="largeFont">A better check is to ask a team of friendly humans to audit your code.</p>
      <p className="largeFont"><a href="https://security.extropy.io">Extropy</a> would be happy to do that.</p>
      </div>
      <br /> 
      </div>
     

    </div>
  );
}

export default App;
