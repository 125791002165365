import React from 'react';
import ReactDom from 'react-dom/client';
import './index.css';
import App from './App';


let container = document.getElementById('root')

const root = ReactDom.createRoot(container);

root.render(<App />);


