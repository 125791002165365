// import { Configuration, OpenAIApi } from "openai";

// export async function setUp() {

//   const configuration = new Configuration({
//     organization: process.env.REACT_APP_ORGANISATION,
//     apiKey: process.env.REACT_APP_APIKEY,
//   });


//   const openai = new OpenAIApi(configuration);
//   return openai;
// }



export async function callLambda(details,qType) {

  const jsonData = {
    "code": details,
    "questionType": qType
  }
  const postBody = JSON.stringify(jsonData);

  const lambdaURL = "https://47xlvu42i5zf4jz72aghfz3anm0vsqfi.lambda-url.eu-west-2.on.aws";
  const response = await fetch(lambdaURL, {  

  method: 'POST',  
  body: postBody 

});
const status =  response.status;
console.log(status);


const body = await response.text();
return JSON.parse(body).content;


}


// export async function runCompletion(openai, contract, questionType) {
//   let question = "what security problems are there in this smart contract ?";
//   if (questionType === "optimise") {
//     question = "what optimisations are possible in this smart contract ?";
//   }
//   console.log(question);
//   const completion = await openai.createChatCompletion({
//     model: "gpt-4",
//     messages: [
//       { "role": "user", "content": question + contract }
//     ]
//   });

//   return completion.data.choices[0].message.content;
// }

